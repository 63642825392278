import React from "react";
import { pipe, get, map, remove } from "lodash/fp";
import { StaticQuery, graphql } from "gatsby";

import IntroHeader from "./IntroHeader";

const query = graphql`
  query {
    ...allBannerImages
  }
`;

const IntroHeaderContainer = props => (
  <StaticQuery
    query={query}
    render={pipe(
      get("allBannerImages.edges"),
      map("page.heroImage"),
      remove({ id: get("heroImage.id", props) }),
      sliderImages => ({ sliderImages }),
      data => (
        <IntroHeader {...props} {...data} />
      )
    )}
  />
);

export default IntroHeaderContainer;
