import React from "react";
import PropTypes from "prop-types";
import Image from "gatsby-image";
import classNames from "classnames";
import Slider from "react-slick";
import { map } from "lodash/fp";
import { imagePositionClasses } from "../../utils/imagePosition";
import { P, H1, H2 } from "../Type";
import "slick-carousel/slick/slick.css";

import { Container } from "../LayoutUtils";
import BookAppointmentButton from "../BookAppointmentButton";
import styles, {
  introHeader,
  textBoxContainer,
  textBox,
  image,
  heading,
  para
} from "./style.module.scss";

const settings = {
  arrows: false,
  fade: true,
  infinite: true,
  speed: 1500,
  autoplaySpeed: 10000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true
};

const IntroHeader = ({
  introHeaderTitle,
  introHeaderSubtitle,
  heroImage,
  imagePosition,
  sliderImages,
  useH2
}) => (
  <div className={introHeader}>
    <div className={textBoxContainer}>
      <Container>
        <div className={textBox}>
          {useH2 ? (
            <H2 className={heading}>{introHeaderTitle}</H2>
          ) : (
            <H1 className={heading}>{introHeaderTitle}</H1>
          )}

          <P className={para}>{introHeaderSubtitle}</P>
          <BookAppointmentButton />
        </div>
      </Container>
    </div>
    <Slider {...settings}>
      <Image
        className={classNames(image, imagePositionClasses(imagePosition))}
        fluid={heroImage.fluid}
        title={introHeaderTitle}
        alt={introHeaderSubtitle}
      />
      {map(i => (
        <Image
          key={i.id}
          className={classNames(image, { [styles.imageTop]: true })}
          fluid={i.fluid}
        />
      ))(sliderImages)}
    </Slider>
  </div>
);

IntroHeader.propTypes = {
  imagePosition: PropTypes.string,
  introHeaderTitle: PropTypes.string.isRequired,
  introHeaderSubtitle: PropTypes.string.isRequired,
  heroImage: PropTypes.shape({
    resize: PropTypes.shape({
      src: PropTypes.string
    })
  }),
  imageTop: PropTypes.bool,
  sliderImages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      heroImage: PropTypes.shape({
        resize: PropTypes.shape({
          src: PropTypes.string
        })
      })
    })
  ),
  useH2: PropTypes.bool
};

IntroHeader.defaultProps = {
  useH2: false,
  imagePosition: "",
  heroImage: null,
  imageTop: false,
  sliderImages: []
};

export default IntroHeader;
