import { pipe, reduce, values, capitalize } from "lodash/fp";
import styles from "./styles.module.scss";

export const IMAGE_POSITIONS = { TOP: "TOP", BOTTOM: "BOTTOM" };

export const imagePositionClasses = imagePosition =>
  pipe(
    values,
    reduce((memo, pos) => {
      return {
        ...memo,
        [styles[`image${capitalize(pos)}`]]: imagePosition === pos
      };
    }, {})
  )(IMAGE_POSITIONS);
