import React from "react";
import { StaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import Button from "../Button";

const query = graphql`
  query {
    contentfulContactInformation {
      email
    }
  }
`;

const BookAppointmentButton = ({ email }) => (
  <Button>
    <a
      title="Book an appointment"
      href={`mailto:${email}?subject=I would like to book an appointment`}
    >
      Book an appointment
    </a>
  </Button>
);

BookAppointmentButton.propTypes = {
  email: PropTypes.string.isRequired
};

const BookAppointmentButtonContainer = props => (
  <StaticQuery
    query={query}
    render={data => (
      <BookAppointmentButton
        email={data.contentfulContactInformation.email}
        {...props}
      />
    )}
  />
);

export default BookAppointmentButtonContainer;
